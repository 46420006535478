<template>
  <div>
    <the-breadcrumbs :custom-breadcrumbs="breadcrumbs"></the-breadcrumbs>
    <page-content page-title="Artikel bearbeiten">
      <article-form
        :loading="loading"
        :article="article"
        :form-submitted="saveArticle"
        mode="edit"
        @image-uploaded="addImage"
      ></article-form>
    </page-content>
    <portal to="fixed-footer-right">
      <delete-button
        v-slot="slotProps"
        :on-delete="deleteArticle"
        subject="Artikel"
      >
        <el-button
          icon="fal fa-trash-alt"
          type="danger"
          @click="slotProps.handleDelete(article)"
        >
          Löschen
        </el-button>
      </delete-button>
    </portal>
  </div>
</template>

<script>
import Vue from "vue"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import DeleteButton from "@/components/DeleteButton.js"
import PageContent from "@/components/PageContent"
import ArticleForm from "@/components/forms/ArticleForm"
import ArticlesRepository from "@/repositories/articles_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Artikel bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    ArticleForm,
    DeleteButton
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      article: {
        content: ""
      }
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          path: "/guide",
          text: "Wissenswertes"
        },
        {
          path: `/article_collections/${this.$route.query.article_collection_id}`,
          text: `${this.$route.query.article_collection_name}`
        }
      ]
    }
  },
  async created() {
    try {
      this.article = await ArticlesRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveArticle(article) {
      await ArticlesRepository.update(article.id, article)
      try {
        await this.$router.push({
          name: "GuidePage",
          query: { allow_redirect: true }
        })
      } catch {
        //
      }
    },
    async addImage({ article, signed_id, command }) {
      try {
        article.images.push(signed_id)
        let response = await ArticlesRepository.update(article.id, article)
        Vue.set(this.article, "images", response.article.images)
        Vue.set(
          this.article,
          "image_attachments",
          response.article.image_attachments
        )
        let attachment = this.article.image_attachments.find(
          a => a.blob_signed_id === signed_id
        )
        command({
          src: `${process.env.VUE_APP_ROOT_API}/articles/${article.id}/get_image/${attachment.id}`
        })
      } catch (error) {
        this.handleApiError(error, "Fehler beim Hochladen vom Bild")
      }
    },
    async deleteArticle(article) {
      await ArticlesRepository.destroy(article.id)
      try {
        await this.$router.push({
          name: "GuidePage",
          query: { allow_redirect: true }
        })
      } catch {
        //
      }
    }
  }
}
</script>
